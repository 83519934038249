@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500&family=Poppins:wght@100;200;400&display=swap');

:root {
  --primary-dark: #5651e5;
  --primary-light: #709dff;
}

@Layer base {
  body {
    @apply font-[Oswald]
  }

  li {
    @apply p-4
  }

}